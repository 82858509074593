<!-- <div class="row justify-content-center">
    <p>
        Auth!
        {{ (auth.user | async)?.uid | json }}
        {{ (auth.credential | async)?.additionalUserInfo.isNewUser | json }}
        <button (click)="login()" *ngIf="showLoginButton">Log in with Google</button>
        <button (click)="loginAnonymously()" *ngIf="showLoginButton">Log in anonymously</button>
        <button (click)="logout()" *ngIf="showLogoutButton">Log out</button>
    </p>
</div> -->
<div class="row justify-content-center align-items-center h-100">
    <div class="col-10 col-md-6 col-lg-4 col-xl-3">
        <img class="w-100" src="assets/svg/tour-life.svg" />
        <form class="mt-3">
            <div class="mb-3">
                <label for="email" class="form-label">Email address</label>
                <input [(ngModel)]="email" type="email" name="email" class="form-control form-control-lg"
                    placeholder="admin@example.com">
            </div>
            <div class="mb-4">
                <label for="password" class="form-label">Password</label>
                <input [(ngModel)]="password" type="password" name="passowrd" class="form-control form-control-lg"
                    placeholder="password">
            </div>
            <button type="submit" class="btn btn-primary btn-lg w-100" (click)="signInWithEmail()">Sign in</button>
            <div *ngIf="errorMessage" class="mt-3 text-danger">
                {{errorMessage}}
            </div>
        </form>
        <hr>
        <div class="btn btn-light btn-lg text-dark w-100" (click)="signInWithGoogle()">
            <img src="assets/png/google.png" class="me-3" style="height: 24px;">
            Sign in with Google
        </div>
        <div class="my-3"></div>
        <div type="button" class="btn btn-primary btn-light btn-lg text-dark w-100" (click)="signInWithGoogle()">
            <img src="assets/png/apple.png" class="me-3" style="height: 24px;">
            Sign in with Apple
        </div>
    </div>
</div>