import { Observable } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-blog-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class BlogDashboardComponent implements OnInit {
  loading: boolean = true;
  blogs: Observable<any[]>;

  listTitle: string;
  listDescription: string;

  constructor(public db: AngularFireDatabase,
    private router: Router) {
    this.blogs = db.list('blogs').valueChanges();

    this.blogs.subscribe(() => this.loading = false);
  }

  ngOnInit(): void {
  }

  goToBlog(id) {
    this.router.navigate([`editor/${id}`]);
  }

  showPopup() {

  }

  addEntry() {
    // this.db.database.ref('blogs').

    this.db.list('/blogs').push({
      "list-title": this.listTitle,
      "list-description": this.listDescription,
    }).then((data) => {
      console.log(data.key);
      this.db.object(`blogs/${data.key}`).update({"id": data.key});
    })
      .catch(error => {
        console.log(error);
      });
  }
}
