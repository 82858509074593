<div class="container-fluid h-100">
  <div class="row h-100 pt-4">
    <div class="col">
      <div class="row">
        <i (click)="goBack()" type="button" class="fa fa-arrow-circle-left text-muted ms-3"
          style="font-size: 32px;"></i>
      </div>
    </div>
    <div class="col-auto">
      <div class="row justify-content-between mb-3">
        <div class="col-2">
          <div (click)="save()" [class.disabled]="saving" class="w-100 btn btn-success text-white">{{saving ?
            'Saving':'Save'}}</div>
        </div>
        <div class="col-auto">
          <div class="text-muted">last saved {{dateSaved | date:'short'}}</div>
        </div>
      </div>
      <div class="card bg-white h-100">
        <div class="mt-4">
          <div *ngIf="loading" class="row" style="width: 740px;">
            <div class="spinner-border text-primary m-auto" role="status"></div>
          </div>
          <quill-editor *ngIf="!loading" theme="bubble" (onEditorChanged)="changed($event)"
            (onEditorCreated)="created($event)" placeholder="Begin Writing..." format="json">
            <div quill-editor-toolbar>
              <span class="ql-formats">
                <button class="ql-bold"></button>
                <button class="ql-italic"></button>
                <button class="ql-blockquote"></button>
                <button class="ql-code-block"></button>
              </span>
              <span class="ql-formats">
                <select class="ql-header">
                  <option selected></option>
                  <option value="1"></option>
                  <option value="2"></option>
                  <option value="3"></option>
                  <option value="4"></option>
                </select>
              </span>
              <span class="ql-formats">
                <select class="ql-align">
                  <option selected></option>
                  <option value="center"></option>
                  <option value="right"></option>
                  <option value="justify"></option>
                </select>
              </span>
              <span class="ql-formats">
                <button class="ql-link"></button>
                <button class="ql-image"></button>
              </span>
            </div>
          </quill-editor>
        </div>
      </div>
    </div>
    <div class="col">
      <div class="row h-100">
        <div class="col mt-4 mx-5">
          NOTES
          <div>
            <textarea [(ngModel)]="newNote" name="note" class="form-control form-control-lg my-3"
              placeholder="..."></textarea>
            <div class="row justify-content-end">
              <div class="col-auto">
                <div (click)="addNote()" [class.disabled]="addingNote" class="btn btn-outline-primary text-white">
                  {{addingNote ?
                  'Adding':'Add Note'}}</div>
              </div>
            </div>
          </div>

          <div *ngFor="let note of notes">
            <div class="mb-3">
            <div style="font-size: 16px;">{{note.text}}</div>
            <small class="text-muted">last saved {{dateSaved | date:'short'}}</small>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- <div id="diff" class="ql-container ql-bubble">
  <div class="ql-editor" [innerHTML]="byPassHTML(form.get('html').value)"></div>
</div> -->

<!-- <quill-view-html [content]="form.get('html').value" theme="bubble"></quill-view-html> -->
