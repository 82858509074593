import { ThisReceiver } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { DatabaseReference } from '@angular/fire/database/interfaces';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['editor.component.scss']
})
export class EditorComponent implements OnInit {
  saving = false;
  addingNote = false;

  loading = true;
  content: any;
  dateSaved: Date;
  blogRef: DatabaseReference;

  newNote: string;
  notes: any[] = [];

  constructor(public db: AngularFireDatabase,
    public router: Router,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private fb: FormBuilder) {
    this.route.params.subscribe(params => {
      this.blogRef = this.db.database.ref(`blogs/${params['id']}`);
    });
    // this.blogs = db.list('blogs').valueChanges();

    // this.blogs.subscribe(() => this.loading = false);
  }


  ngOnInit(): void {
    this.blogRef.once('value').then((data) => {
      var value = data.val();
      if (value.notes != null) this.notes = value.notes;
      if (value.content != null) this.content = JSON.parse(value.content);
      if (value.dateSaved != null) this.dateSaved = new Date(Date.parse(value.dateSaved));
      this.loading = false;
    });
  }

  created(editor) {
    if (this.content != null) editor.setContents(this.content.content, this.content.delta);
  }

  changed(e) {
    if (e.content != null) {
      this.content = {
        "content": e.content,
        "delta": e.delta,
        "html": e.html,
      };
      console.log(this.content);
    }
  }

  save() {
    if (this.saving) return;
    this.saving = true;
    this.blogRef.update({ "content": JSON.stringify(this.content), "dateSaved": new Date() }).then((data) => {
      this.saving = false;
    });
  }
  
  addNote() {
    if (this.addingNote) return;
    this.addingNote = true;
    this.blogRef.update({ "notes": [{"text": this.newNote, "date": new Date() }, ...this.notes] }).then((data) => {
      this.addingNote = false;
      this.notes = [{"text": this.newNote, "date": new Date()}, ...this.notes];
    });
  }

  goBack() {
    this.router.navigate(['dashboard']);
  }
}

// byPassHTML(html: string): SafeHtml {
//   console.log(html);
//   return this.sanitizer.bypassSecurityTrustHtml(html);
// }
